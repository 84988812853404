/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types';
import { notMarketableShopRoutesMap, navigationTypeRoutesMap } from '~/constants/navigation';
import storeMutations from '~/store/constants/storeMutations';
import navTypes from '~/store/constants/navTypes';
import { Route } from 'vue-router';

export default async function general({
  store,
  route,
  from,
  app,
}: Context) {
  store.commit(storeMutations.HIDE_SHOP_BUTTON, notMarketableShopRoutesMap.has(route.name));
  store.commit(storeMutations.SET_NAV_TYPE, getNavType(route));
  store.commit(storeMutations.IS_IN_STORYBLOK, !!route.query._storyblok);
  store.commit(storeMutations.SET_CURRENT_ROUTE, { path: route.path, name: route.name });

  /*
  dispatch getCollection action which makes a request to Shopify to get products.
  We do this logic here so a request is made on every page load and Shopify data/updates will be live in real time
  */
  await store.dispatch('getCollection', { id: process.env.NUXT_ENV_SHOPIFY_COLLECTION_ID });

  if ((from ?? {}).name === 'shop-checkout' && store.state.checkout.cart.length && route.name !== 'shop-confirmation') {
    const getterBundle = store.getters.bundle;

    if (process.client) {
      app.$ecommAnalytics.abandonedCart(getterBundle);
    }
  }
  return null;
}

function getNavType(route: Route) {
  const routeName = route.name === 'lp-slug' ? route.params.slug : route.name;
  return navigationTypeRoutesMap.get(routeName) || navTypes.primary;
}
